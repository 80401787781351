<template>
  <div>
    <loadingScreen :isLoading="isLoading"/>
    <page-header></page-header>
    <div>
      <card-component></card-component>
    </div>
    <!--    <div class="row gutters">-->
    <!--      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">-->
    <!--        <div class="card h-100 mt-5">-->
    <!--          <div class="d-flex flex-wrap w-100 mt-5">-->
    <!--            <div class="p-5 mt-5"></div>-->
    <!--            <div class="card-body w-50 h-150">-->
    <!--              <router-link to="/running">-->
    <!--                <button id="button-card-aguardando" class="btn btn-secondary w-100 h-100">-->
    <!--                  <div>-->
    <!--                    <span class="menu-text" style="font-size: 40px">{{ waiting }}</span><br>-->
    <!--                    <i class="icon-clock1"></i>-->
    <!--                    <span class="menu-text" style="margin-left: 10px">{{ this.t('DOCUMENTS.MENU.WAITING') }}-->
    <!--                        </span>-->
    <!--                  </div>-->
    <!--                </button>-->
    <!--              </router-link>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      </div>-->
  </div>

</template>

<script>

import CardComponent from '@/components/layouts/cardComponent';
import LoadingScreen from '@/components/layouts/loadScreenComponent';
import PageHeader from '@/components/layouts/pageHeader';
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";

export default {
  name: 'HomeComponent',
  components: {
    CardComponent,
    LoadingScreen,
    PageHeader
  },

  setup() {
    const toast = useToast();
    const {t} = useI18n();
    return {t, toast}
  },

  data() {
    return {
      isLoading: null,
    }
  },
}
</script>

<style>

</style>
