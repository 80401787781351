<template>
  <div class="row gutters">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
      <div class="card h-100">
        <div class="card-header text-left p-4 h-30">
          <div class="card-title" style="padding-top: 3px">{{ this.t('DOCUMENTS.THIS_MOMENT') }}:</div>
        </div>
        <div class="d-flex flex-wrap w-100">
          <div class="card-body w-50 h-150">
            <router-link to="/running">
              <button id="button-card-aguardando" class="btn btn-secondary w-100 h-100">
                <div>
                  <span class="menu-text" style="font-size: 40px">{{ waiting }}</span><br>
                  <i class="icon-clock1"></i>
                  <span class="menu-text" style="margin-left: 10px">{{ this.t('DOCUMENTS.MENU.WAITING') }}
                        </span>
                </div>
              </button>
            </router-link>
          </div>
          <div class="card-body w-50 h-150">
            <router-link to="/canceled">
              <button id="button-card-cancelados" class="btn btn-secondary w-100 h-100">
                <div>
                  <span class="menu-text" style="font-size: 40px">{{ canceleds }}</span><br>
                  <i class="icon-cancel"></i>
                  <span class="menu-text" style="margin-left: 10px">{{ this.t('DOCUMENTS.CANCELEDS') }}</span>
                </div>
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
      <div class="card h-100">
        <div class="card-header text-left">
          <div class="card-title text-left h-auto">
            <select2 style="padding-top: 1px" :options="untilDay" :settings="{ width: '30%', height: '10%' }"
                     v-model="until" @select="findByData($event)"></select2>
          </div>
        </div>
        <div class="d-flex flex-wrap w-100">
          <div class="card-body w-50 h-150">
            <router-link to="/signed">
              <button id="button-card-finalizados" class="btn btn-secondary w-100 h-100" style="margin-top: -3px">
                <div style="text-align: center">
                  <span class="menu-text" style="font-size: 40px">{{ closed }}</span><br>
                  <i class="icon-file-text"></i>
                  <span class="menu-text" style="margin-left: 10px">{{ this.t('DOCUMENTS.MENU.SIGNEDS') }}
                        </span>
                </div>
              </button>
            </router-link>
          </div>
          <div class="card-body w-50 h-150">
            <router-link to="/all">
              <button id="button-card-all" class="btn btn-secondary w-100 h-100" style="margin-top: -3px">
                <div style="text-align: center">
                  <i class="icon-documents"></i>
                  <span class="menu-text" style="margin-left: 10px">{{ this.t('DOCUMENTS.ALL') }}
                        </span>
                </div>
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import Select2 from 'vue3-select2-component';
import Documents from '@/services/Documents';

export default {
  name: 'CardComponent',
  components: {
    Select2
  },

  setup() {
    const toast = useToast();
    const {t} = useI18n();
    return {t, toast}
  },

  data() {
    return {
      until: "",
      inProgress: 0,
      untilDay: [],
      canceleds: 0,
      waiting: 0,
      closed: 0
    }
  },

  mounted() {
    this.options();
    this.until = "Últimos 30 dias";
    this.indexAt(this.until);
    this.index();
  },

  methods: {
    findByData(event) {
      this.$store.commit('changeLoading', true);
      this.indexAt(event.id)
    },

    indexAt(until) {
      let treatedUntil = this.treatmentOfArray(until);

      Documents.indexAt(treatedUntil).then((resp) => {
        if(resp.data) {
          this.inProgress = resp.data.signed
        }

        this.$store.commit('changeLoading', false);
      }).catch((err) => {
        console.log(err)
        this.$store.commit('changeLoading', false);
      });
    },

    index(){
      this.$store.commit('changeLoading', true)
      Documents.getRawData().then((resp) => {
        this.canceleds = resp.data.canceleds;
        this.waiting = resp.data.running;
        this.closed = resp.data.closed;
        this.$store.commit('changeLoading', false)
      }).catch((err) => {
        this.$store.commit('changeLoading', false)
        console.log(err)
      })
    },

    options() {
      this.untilDay.push('Últimos 30 dias', 'Últimos 15 dias', 'Últimos 7 dias');
    },

    treatmentOfArray(until) {
      switch (until) {
        case 'Últimos 30 dias':
          return '30';
        case 'Últimos 15 dias':
          return '15';
        case 'Últimos 7 dias':
          return '7';
        default:
          return "";
      }
    }
  }
}
</script>

<style>
#button-card-aguardando {
  font-size: 20px;
  color: #E96629;
  border-radius: 25px;
  border: none;
  background-color: #F69B5A;
  transition: 0.2s;
}

#button-card-aguardando:hover {
  background-color: darkorange;
  color: white;
}

#button-card-cancelados {
  border-radius: 25px;
  font-size: 20px;
  border: none;
  color: #E14327;
  background-color: #E38B7C;
  transition: 0.2s;
}

#button-card-cancelados:hover {
  background-color: #E53313;
  color: white;
}

#button-card-finalizados {
  font-size: 20px;
  color: #0e5e4e;
  border-radius: 25px;
  border: none;
  background-color: #C3E3DD;
  transition: 0.2s;
}

#button-card-finalizados:hover {
  background-color: #0e5e4e;
  color: white;
}

#button-card-all {
  font-size: 28px;
  color: #7A7F0E;
  border-radius: 25px;
  border: none;
  background-color: #E9F065;
  transition: 0.2s;
}

#button-card-all:hover {
  background-color: #F2F617;
  color: black;
}


</style>
